@import '~ngx-toastr/toastr';
@import "~nouislider/dist/nouislider.min.css";
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~swiper/swiper-bundle.css";
@import "~swiper/swiper-bundle.min.css";
.ql-editor {
  min-height: 100px;
}

.ql-mention-list {
  background-color: white;
  list-style-type: none;
  transform: translateY(-2px);
  margin-top: 0;
  /*padding: 0px 0px 0px 20px;*/
  padding: 10px;
  z-index: 100;
  border: 1px solid #ccc;
  border-radius: 3px;
  position: relative;
}

.ql-mention-list .ql-mention-list-item {
  cursor: pointer;
  padding: 10px 10px;
  text-align: left;
}

.ql-mention-list .ql-mention-list-item.selected {
  background-color: rgba(0, 0, 0, 0.12);
}

span.mention {
  background-color: rgba(0, 0, 0, 0.12);
  padding: 2px 5px;
  border-radius: 2px;
}

body{padding:0;margin:0;font-size:14px;font-weight:normal;line-height:normal;font-family:"Montserrat",sans-serif}
*{font-family:"Montserrat",sans-serif}
.btn.focus, .btn:focus {box-shadow: none !important;}
.arrived-at-warehouse-status{background: #6d709a !important}

.bs-datepicker-body table td span.selected.disabled, .bs-datepicker-body table td.selected.disabled span {
  opacity: 0.3;
  color: white !important;
}

@media screen and (max-width: 1600px) {
  .highcharts-legend-item text tspan {font-size: 13px}
}
@media screen and (max-width: 1500px) {
  .table-main {
    overflow: auto !important;
  }
}
@media screen and (max-width: 1399px) {
  .highcharts-legend-item text tspan {font-size: 12px}
}
@media screen and (max-width: 991px) {
  highcharts-chart:not(.device,.map) {min-width: 800px;}
}


:-webkit-scrollbar-track
{
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar
{
  border-radius: 10px;
  width: 6px;
  height: 6px;
  background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: #ccc;
}

body.dashboard-active {
  background: #222324;height: 100vh;
}

.mention {
  background-color: #c2e7ff !important;
  font-weight: 600;
  padding: 4px 4px !important;
  border-radius: 4px !important;
  font-size: 13px;
  line-height: 32px !important;
  font-family: "Montserrat", sans-serif;
  color: #212529;
}

.btn-secondary.focus, .btn-secondary:focus{box-shadow: none}

.order-status-tooltip-custom-class .tooltip-inner{max-width: 400px}
.order-status-tooltip-custom-class .tooltip-inner .mention{background: transparent !important;color: #fff}


/* Swiper Css */
.imageEnlarge { background: rgba(0, 0, 0, 0.6) }
.imageEnlarge .modal-dialog { max-width: 100% }
.imageEnlarge .modal-dialog .modal-content { background: transparent; box-shadow: none; }
.imageEnlarge .product-list-navigation .product-prev-image { right: auto; left: 10px }
.imageEnlarge .product-list-navigation .product-next-image { right: 15px; left: auto }
.imageEnlarge .product-list-navigation-arrow { background: #000; color: #fff; margin: 0 }
.imageEnlarge .product-gallery-image {height: calc(100vh - 60px); -webkit-align-items: center; align-items: center; -webkit-justify-content: center; justify-content: center; display: -webkit-box; display: -moz-box; display: -ms-flexbox; display: -webkit-flex; display: flex; flex-wrap: wrap }
.imageEnlarge .product-enlarge-gallery .product-gallery-image img { height: auto; width:100%; max-height: 100%; max-width: 100%; vertical-align: middle }
.swiper-container { overflow: visible; width: 100%; }

.swiper-container { overflow: visible; width: 100%; }
.service-list-section-slider .swiper-pagination { bottom: 30px !important; left: 35px !important; width: unset !important; right: auto !important; }
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet { margin: 0 4px; cursor: pointer; }
.swiper-pagination-bullet { width: 10px; height: 10px; background: transparent; opacity: 1; border: 2px solid #fff; margin: 0 5px; }
.swiper-pagination-bullet.swiper-pagination-bullet-active { background: #ffffff; border-color: #ffffff; }
/*.detail-product-gallery .swiper-container .swiper-wrapper {height: 100% !important;}*/
.finish-widget.scroll-section-widget .swiper-slide{ width: 209px!important;}
.product-list-navigation-arrow {position: absolute;top: 50%;transform: translateY(-50%);z-index: 9;box-shadow: 0 0 4px rgba(0,0,0,.14);background: hsla(0,0%,100%,.8);width: 39px;height: 39px;text-align: center;border-radius: 50%;line-height: 39px;cursor: pointer;outline: none;margin-top: -30px;}
.product-list-navigation-arrow span {font-size: 25px !important;line-height: 39px !important;}
.product-list-next {left: auto;right: -20px;}
.product-list-prev {right: auto;left: 20px;}
.swiper-button-disabled {display: none;}
.product-gallery .show-in-mobile {display: none;}
.product-gallery {margin-bottom: 40px;}
.align-items-center {align-items: center;}
.product-gallery-images {padding: 0 15px;width: 543px;}

.icon-right-caret:before {
  font-family: icomoon, icomoon-1 !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
  content: "\e914";
}

.icon-left-caret:before {
  font-family: icomoon, icomoon-1 !important;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    content: "\e910";
}

.collapse-content {
  padding-bottom: 20px;
}
.download-icon-custom {
  background: transparent !important;
}
